<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="primary" to="/customer/customer-add">
          <span class="text-nowrap">Add Customer</span>
        </b-button>
      </div>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">
      <!-- <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">-- none --</option>
            </template>
          </b-form-select>
          <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
            <option :value="false">Asc</option>
            <option :value="true">Desc</option>
          </b-form-select>
        </b-input-group>
      </b-form-group> -->
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="customerList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(companyName)="data">
          <b-badge v-if="data.item.companyName === null" variant="primry">
            Not a Company
          </b-badge>

          {{ data.item.companyName }}
        </template>
        <template #cell(surname)="data">
          <b-badge v-if="data.item.surname === null" variant="secondary">
            {{ data.item.companyName }}
          </b-badge>

          {{ data.item.surname }}
        </template>

        <template #cell(otherName)="data">
          <b-badge v-if="data.item.otherName === null" variant="secondary">
            {{ data.item.companyName }}
          </b-badge>

          {{ data.item.otherName }}
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 1" variant="info"> Booked </b-badge>
          <b-badge v-else-if="data.item.status === 2" variant="primary">
            Available
          </b-badge>
          <b-badge v-else-if="data.item.status === 3" variant="warning">
            Maintainace
          </b-badge>
          <b-badge v-else-if="data.item.status === 4" variant="danger"> Broken </b-badge>
          <b-badge v-else variant="danger"> Not Available </b-badge>
        </template>
        <template #cell(salesPersonId)="data">
          <p v-if="data.item.salesPersonId === null">-</p>
          <p v-else>{{ getSalePersonName(data.item.salesPersonId) }}</p>
        </template>

        <template #cell(createdOn)="data">
          {{ formatDate(data.item.createdOn) }}
        </template>
        <template #cell(actions)="data">
          <!-- <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item :to="getCustomerData(data.item.id)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeCustomer(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown> -->
          <b-button variant="primary" :to="getCustomerData(data.item.id)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>View</span>
          </b-button>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
      <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1 }} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  data() {
    return {
      companyName: "",
      status: "",
      perPage: 5,
      customerData: [],
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "otherName", label: "Customer Name", sortable: true },

        { key: "typeDesc", label: "Customer Type" },
        { key: "email", label: "Email", sortable: true },
        { key: "salesPersonId", label: "Sales Person" },
        { key: "createdOn", label: "Created On", sortable: "true" },
        "actions",
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("customerModule", {
      customerList: "customers",
      loading: "loading",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.customerList.length;
  },
  async mounted() {
    await this.getSalesPersonListAction();
    await this.getCustomersListAction()
      .then(() => {
        this.totalRows = this.customerList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
  methods: {
    ...mapActions("customerModule", [
      "getCustomersListAction",
      "deleteCustomersAction",
      "updateCustomersAction",
    ]),

    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),

    formatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
    },

    getCustomerData(data) {
      return "/customer/customer-view/" + data;
      //this.customerData = data;
    },

    getCustomerEditData(data) {
      return "/customer/customer-edit/" + data;
      //this.customerData = data;
    },

    removeCustomer(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCustomersAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Customer has been deleted",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Customer not deleted:)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
